import {AdvancedPatientFilterModel, AdvancedPatientLookupSearchParams} from '@matchsource/models/patient';

export class AdvancedLookupPatients {
  static readonly type = '[AdvancedPatientLookup] Load';

  constructor(public readonly searchParams: Partial<AdvancedPatientLookupSearchParams>) {}
}

export class AdvancedLookupPatientsSearch {
  static readonly type = '[AdvancedPatientLookup] Search';

  constructor(public readonly filters: Partial<AdvancedPatientFilterModel[]>) {}
}

export class AdvancedLookupPatientsClear {
  static readonly type = '[AdvancedPatientLookup] Clear';
}
