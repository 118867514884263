import {FileFormat} from 'app/shared';
import {PatientStatusShortName} from '@matchsource/models/patient';

export enum AdvancedPatientLookupRoutes {
  AdvancedLookup = 'advanced-lookup',
  AdvancedLookupSelectTC = 'advanced-lookup.select-tc',
  AdvancedLookupOverview = 'advanced-lookup.overview',
}

export interface FileFormatListModel {
  name: string;
  value: FileFormat;
}

export interface PatientStatusModel {
  code: PatientStatusShortName;
  description: string;
}

export interface AdvancedPatientSearchFormModel {
  patientStatus: PatientStatusShortName;
  prlmFrom: MsApp.DateString;
  prlmTo: MsApp.DateString;
  frmlFrom: MsApp.DateString;
  frmlTo: MsApp.DateString;
  transferFrom: MsApp.DateString;
  transferTo: MsApp.DateString;
}
